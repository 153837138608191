import React from "react"

const DisclaimerComponent = () => {
  const links = {
    "cookie-policy":
      "https://insuranceoctopus.co.uk/cookie-policy/?utm_source=bing&utm_medium=cpc&utm_term=professional%2Bindemnity&utm_campaign=GPPCPI&gclid=CLz3gPDd4e0CFQ7hGwodggoB1A",
    "privacy-policy":
      "https://insuranceoctopus.co.uk/privacy-policy/?utm_source=bing&utm_medium=cpc&utm_term=professional%2Bindemnity&utm_campaign=GPPCPI&gclid=CLz3gPDd4e0CFQ7hGwodggoB1A",
    imprint: "/",
    "contact-mail": "kontakt@bolttech.at",
    "trade-authority":
      "https://www.bmdw.gv.at/Themen/Unternehmen/Versicherungsvermittlung.html",
    ris: "www.ris.bka.gv.at",
  }
  return (
    <div className="tnc-section bg-white">
      <section className="container tnc-wrapper">
        <h3 className="bold title">Impressum</h3>
        <p>
          Informationspflicht gemäß § 5 E-Commerce Gesetz (ECG), § 14
          Unternehmensgesetzbuch (UGB), § 63 Gewerbeordnung (GewO) und der §§
          24, 25 Mediengesetz (MedienG).
        </p>
        <p>
          <strong>Firmenwortlaut</strong>: BOLTTECH DEVICE PROTECTION (AUT)
          GmbH.
        </p>
        <p>
          <strong>Rechtsform</strong>: Gesellschaft mit beschränkter Haftung
        </p>
        <p>
          <strong>Sitz der Gesellschaft</strong>: Kohlmarkt 8-10, 1010 Wien
          Österreich
        </p>
        <p>
          <strong>Telefon</strong>: +43 1 227461075
        </p>
        <p>
          <strong> E-Mail: </strong>
          <a href={"mailto:" + links["contact-mail"]}>
            {links["contact-mail"]}
          </a>
        </p>
        <p>
          <strong>Unternehmensgegenstand:</strong>Erbringung von
          Dienstleistungen betreffend Schutz (device protection) und Servicing
          (device servicing) von elektrischen und elektronischen Geräten.
        </p>
        <p>
          <strong>UID-Nummer:</strong> ATU75362527
        </p>
        <p>
          <strong>Firmenbuchnummer:</strong> 507941y
        </p>
        <p>
          <strong>Firmenbuchgericht:</strong> Handelsgericht Wien
        </p>
        <p>
          <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Magistratisches
          Bezirksamt für den ersten Wiener Gemeindebezirk, MBA 1/8
        </p>
        <p>
          <strong>Kammerzugehörigkeit:</strong> Wirtschaftskammer Wien
        </p>
        <p>
          <strong>Berufsrecht:</strong>  Gewerbeordnung 1994, abrufbar unter:
          <a href={"https://" + links.ris} target="_blank">
             {links.ris}
          </a>
        </p>
        <p>
          <strong>Verleihungsstaat:</strong> Österreich
        </p>
        <p>
          <strong> Geschäftsführer:</strong> Andreas Pauly
        </p>
        <p>
          <strong>Gesellschafter:</strong> BOLTTECH HOLDINGS (EUROPE) SARL,
          Luxemburg
        </p>
        <p>
          <strong>Blattlinie:</strong> Diese Webseite dient der Bewerbung der
          durch die BOLTTECH DEVICE PROTECTION (AUT) GmbH bereitgestellten
          Produkte und Dienstleistungen sowie der Bewerbung des Unternehmens.
        </p>
      </section>
    </div>
  )
}
export default DisclaimerComponent
